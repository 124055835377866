import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CidResponse } from './../../interfaces/cid-response.interface';


@Component({
  selector: 'wkd-cid-form',
  templateUrl: './cid-form.component.html',
  styleUrls: ['./cid-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CidFormComponent),
      multi: true
    }
  ]
})
export class CidFormComponent implements OnInit, ControlValueAccessor {
  public value: Set<CidResponse> = new Set<CidResponse>();
  public disabled: boolean;
  @Output() hasCidChange = new EventEmitter<boolean>();
  @Input() public cidLimit: number;
  @Input() public required: boolean;
  @Input() public hasCid: boolean;
  @Input() public workRelated: boolean;
  onChange = (value: CidResponse[]) => {};
  onTouched = () => {};
  open: boolean;
  cidToRemove: CidResponse;

  constructor() { }

  ngOnInit(): void {
  }

  public updateCidList(cid: CidResponse) {
    if (this.value.size < this.cidLimit && !this.exists(cid)) {
      this.value.add(cid);
      this.onChange(Array.from(this.value));
    }
  }

  private exists(cid: CidResponse){
    const cidListArray = Array.from(this.value);
    return !!cidListArray.find((c) => c.cid === cid.cid);
  }

  public removeCid(cid: CidResponse) {
    this.value.delete(cid);
    this.onChange(Array.from(this.value));
  }

  public openDialog(cid: CidResponse){
    this.open = true;
    this.cidToRemove = cid;
  }

  writeValue(obj: Set<CidResponse>): void {
    if (obj){
      this.value = obj;
      this.onChange(Array.from(this.value));

    }
  }
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeCidRequiredValue(event) {
    if(event.value === 'Y'){
      this.hasCidChange.emit(true);
    } else {
      this.value = new Set<CidResponse>();
      this.onChange(Array.from(this.value));
      this.hasCidChange.emit(false);
    }
  }
}
