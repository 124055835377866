import { DocumentInfo } from '../../interfaces/document-info.interface';
import { DssUploadComponent } from '@dss/components';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'wkd-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  files: DocumentInfo[];
  @ViewChild('upload') public uploadElement: DssUploadComponent;
  @Input() public required = false;
  @Output() public change: EventEmitter<FileList> = new EventEmitter<FileList>();

  public nameIsInvalid = false;
  public dateToday!: string;
  public extension!: string;

  private _multiple = true;
  public get multiple() : boolean {
    return this._multiple;
  }

  @Input() public set multiple(v : boolean) {
    this._multiple = v;
    this.uploadElement.inputFile.nativeElement.multiple = this._multiple;
  }

  private _accept = '*';
  public get accept() : string {
    return this._accept;
  }

  @Input() public set accept(v : string) {
    this._accept = v;
    this.uploadElement.inputFile.nativeElement.accept = this._accept;
  }

  constructor() { }

  ngOnInit() { }

  valueChange(e: FileList) {
    const response =  new DataTransfer();
    Object.keys(e).forEach((key,index) => {
      const isValid = !isNaN(Number(key));
      if(isValid) {
        response.items.add(e[key]);
      } else if (!isNaN(Number(key))) {
        this.nameIsInvalid = true;
      }

    });

    if(!this.nameIsInvalid) {

      this.uploadElement.files = null;
      this.uploadElement.title = '';
      const emitFile = this.allowedFiles(response.files);

      if(emitFile.length > 0){
        this.change.emit(emitFile);
        this.files = null;
      } else {
        return false;
      }
    }else{
      return false;
    }


  }

  private allowedFiles(e: FileList) {
    if( this.accept === '*') {
      return e;
    } else {
      const response =  new DataTransfer();

      Object.keys(e).forEach((key,index) => {
        if((e[key] instanceof File )) {
          const extension = e[key].name.substr(e[key].name.lastIndexOf('.') + 1).toLocaleLowerCase();

          if(this.accept.toLowerCase().includes(extension)) {
            response.items.add(e[key]);
          }
        }
      });

      return response.files;
    }
  }

  public validateFileName (name: string) {
    const today = new Date();
    const todayString = today.toLocaleDateString('pt-BR').replace(/\//g, '');
    const nameString = name.slice(0, name.lastIndexOf('.'));
    const pattern = /[^A-Za-z0-9\s]+/g;
    const matches = nameString.match(pattern);

    if(matches === null){
      return true;
    }

    this.dateToday = todayString;
    this.extension = name.substr(name.lastIndexOf('.') + 1).toLocaleLowerCase();

    return false;
  }

  public closeModal(){
    this.nameIsInvalid = false;
  }
}
