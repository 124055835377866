import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DssDatepickerComponent } from '@dss/components/datepicker';
import { EMPTY, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbsenceType } from '../../enum/absence-type.enum';
import { ApiBoolean } from '../../enum/api-boolean.enum';
import { ManipulationDatetimeService } from '../../services';
import { AlertMessageTypeForm } from './alert-message-type-form';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectedType } from '../../model/selected-type';

const CONTRACT_DETERMINED = 'S';
const CONTRACT_INDETERMINED = 'N';

const REASON_CODE_ADOPTION = 20;

const i16 = 16;
const i17 = 17;
const i18 = 18;
const i19 = 19;
const num6 = 6;
const num7 = 7;
const num16 = 16;
const num17 = 17;
const num18 = 18;
const num19 = 19;
@Component({
  selector: 'wkd-type-form',
  templateUrl: './type-form.component.html',
  styleUrls: ['./type-form.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('hidden', style({
        opacity: 0,
        display: 'none'
      })),
      state('visible', style({
        opacity: 1,
        display: 'block'
      })),
      transition('hidden => visible', [
        animate('175ms ease-in-out')
      ]),
      transition('visible => hidden', [
        animate('175ms ease-in-out')
      ])
    ])
  ]
})
export class TypeFormComponent implements OnInit {
  @Input() public absenceType: AbsenceType;
  public type = AbsenceType;
  @Input() public formGroupName: string;
  @Input() finalDateError: boolean;
  @Input() startDateError: boolean;
  @Output() startDateErrorChange = new EventEmitter<boolean>();
  @Output() finalDateErrorChange = new EventEmitter<boolean>();
  @Output() selectedType = new EventEmitter<SelectedType>();
  @Input() checkBirthDateError: boolean;
  @Output() checkBirthDateErrorChange = new EventEmitter<boolean>();
  @ViewChild('endDate') public endDate: DssDatepickerComponent;
  @ViewChild('initDate') private readonly initDate: DssDatepickerComponent;
  @ViewChild('bornDate') private readonly bornDate: DssDatepickerComponent;
  @ViewChild('days') private readonly days: DssDatepickerComponent;
  public form: FormGroup;
  private readonly destroy$: Subject<any> = new Subject();
  public contractDateError = false;
  public alertMessage!: string;
  
  public openPopUpWorkRelated = false;
  public openPopUpLicencaPaternidade = false;
  public openPopUpLicencaMaternidade = false;

  public startDateFilled = false;
  public daysInserted = false;
  public NotFixedPeriod = true;

  get disableEndDate() {
    return !!(this.form.controls.type.value && this.form.controls.type.value.fixedPeriod && this.form.controls.type.value.fixedPeriod.toUpperCase() === ApiBoolean.TRUE);
  }
  get reasonEnabled() {
    return this.form.controls.type.value && this.form.controls.type.value.reason === ApiBoolean.TRUE;
  }
  get limitedDate() {
    return this.form.controls.type.value && this.form.controls.type.value.limitedPeriod === ApiBoolean.TRUE;
  }
  get enabledCheckBirth() {
    return this.form.controls.type.value && this.form.controls.type.value.childBirthCheckEnabled && this.form.controls.type.value.childBirthCheckEnabled === ApiBoolean.TRUE;
  }
  constructor(private  readonly rootFormGroup: FormGroupDirective,
    private readonly cd: ChangeDetectorRef,
    private readonly dateService: ManipulationDatetimeService) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.observeForm();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
  private observeForm() {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (a) => {
        this.checkFinalDate();
        this.checkInitialDate();
        this.checkBirthDate();
      }
    );
    this.form.controls.reason.valueChanges.pipe(
                                                  takeUntil(this.destroy$)
                                                ).subscribe(
                                                  ()=>{

                                                    if(this.absenceType === this.type.MATERNITY) {
                                                        this.changeBithdateValidator();
                                                        this.changeAdoptationValidator();
                                                      }
                                                  }
                                                )
      this.form.controls.type.valueChanges.pipe(
                                              takeUntil(this.destroy$)
                                            ).subscribe(
                                              ()=>{

                                                console.log(this.form.controls.type.value)

                                                if(this.form.controls.type.value.fixedPeriod === 'N') {
                                                  this.NotFixedPeriod = true;
                                                } else {
                                                  this.NotFixedPeriod = false;
                                                  this.form.controls.daysForm = this.form.controls.type.value.numPeriod
                                                }

                                                this.finalDate();
                                                this.reasonValidator();
                                                
                                                this.selectedType.emit({
                                                  workRelated: this.form.controls.type.value.workRelated == 'S',
                                                  higherThan15Days: this.form.controls.type.value.periodLimitMin == 16
                                                })
                                                
                                                if(this.form.controls.type.value.workRelated == 'S'){
                                                  this.openPopUpWorkRelated = true;
                                                }

                                                if(this.form.controls.type.value.numPeriod === 28 
                                                    && this.form.controls.type.value.description.toUpperCase().includes("PATERNIDADE")) {
                                                      this.openPopUpLicencaPaternidade = true;
                                                }

                                                if(this.form.controls.type.value.numPeriod === 120 
                                                  && this.form.controls.type.value.description.toUpperCase().includes("MATERNIDADE")
                                                  && this.form.controls.type.value.description.toUpperCase().includes("ATESTADO")) {
                                                    this.openPopUpLicencaMaternidade = true;
                                                }

                                                if(this.form.controls.type.value.contractFixed === CONTRACT_DETERMINED){
                                                  this.contratcFinalDate();
                                                }
                                              }
                                            )
      this.form.controls.startDate.valueChanges.pipe(
                                                  takeUntil(this.destroy$)
                                                ).subscribe(
                                                  ()=>{
                                                    if(this.form.controls.startDate.value === ''){
                                                      this.startDateFilled = false
                                                    } else {
                                                      this.startDateFilled = true;
                                                      this.finalDate();
                                                    }
                                                  }
                                                )
      this.form.controls.daysForm.valueChanges.pipe(
                                                  takeUntil(this.destroy$)
                                                ).subscribe(
                                                  ()=>{
                                                    if(this.form.controls.daysForm.value === 0 || 
                                                      this.form.controls.daysForm.value === null
                                                    ) {
                                                      this.form.controls.daysForm.setValue('')
                                                    } else {
                                                      this.daysInserted = true;
                                                      this.validateDays();

                                                    }
                                                  }
                                                )
      this.form.controls.endDate.valueChanges.pipe(
                                                  takeUntil(this.destroy$)
                                                ).subscribe(
                                                  ()=>{
                                                    if(this.form.controls.type.value.contractFixed === CONTRACT_DETERMINED){
                                                      this.contratcFinalDate();
                                                    }
                                                  }
                                                );
  }

  public validateDays() {
    const days = this.form.controls.daysForm.value;
    this.form.controls.endDate.setValue(this.calculateDate(days).toLocaleDateString('pt-BR'));
    return days;
  }

  public contratcFinalDate() {
    const type = this.form.controls.type.value.id;
    const contractEndDt = this.form.controls.type.value.contractEndDt ?
      new Date(this.dateService.convertPtBRtoGlobal(this.form.controls.type.value.contractEndDt)) : '';
    const endDate = this.form.controls.endDate.value ?
        new Date(this.dateService.convertPtBRtoGlobal(this.form.controls.endDate.value)) : '';

    const noApplyRules = [i16, i17, i18, i19];
    if(endDate > contractEndDt && !noApplyRules.includes(type)){
      this.contractDateError = !this.contractDateError;
      this.alertMessage = AlertMessageTypeForm.getMessageFromType(type);
    }
  }

  public closeModal() {
    this.contractDateError = !this.contractDateError;
    const date = new Date(this.dateService.convertPtBRtoGlobal(this.form.controls.type.value.contractEndDt));

    this.form.controls.endDate.setValue('');
    this.form.controls.endDate.setValue(date.toLocaleDateString('pt-BR'));
    return this.form.controls.endDate.value;
  }

  public minFinalDate() {
    return this.form.controls.startDate.value ?
      (this.limitedDate && this.form.controls.type.value.periodLimitMin ?
        this.calculateDate(this.form.controls.type.value.periodLimitMin) :
        new Date(this.dateService.convertPtBRtoGlobal(this.form.controls.startDate.value))) :
      new Date()
  }
  public maxFinalDate() {
    return this.form.controls.startDate.value ?
      (this.limitedDate && this.form.controls.type.value.periodLimitMax ?
        this.calculateDate(this.form.controls.type.value.periodLimitMax) :
        undefined) :
      undefined
  }

  private calculateDate(days: number) {
    const date = new Date(this.dateService.convertPtBRtoGlobal(this.form.controls.startDate.value));
    date.setDate(date.getDate() + days - 1);
    const invalidDate = isNaN(date.getTime());
    return invalidDate ? undefined : new Date(this.dateService.convertPtBRtoGlobal(date.toLocaleDateString('pt-BR')))
  }
  public minStartDate() {
    const today = new Date();
    today.setDate(today.getDate() - num7);
    return today;
  }

  private changeAdoptationValidator() {
    if (this.isAdoption()) {
      this.form.controls['childAdoptionDate'].setValue(this.form.controls['childAdoptionDate'].value);
      this.form.controls["childAdoptionDate"].updateValueAndValidity();
    }
    else {
      this.form.controls['childAdoptionDate'].setValue(null);
      this.form.controls["childAdoptionDate"].updateValueAndValidity();
    }
  }


  private changeBithdateValidator() {
    if (this.isBorn()) {
      this.form.controls['childBirthDate'].setValidators(Validators.required);
      this.form.controls['childBirthDate'].setValue(this.form.controls['childBirthDate'].value);
      this.form.controls["childBirthDate"].updateValueAndValidity();
    }
    else {
      this.form.controls['childBirthDate'].clearValidators();
      this.form.controls['childBirthDate'].setValue(null);
      this.form.controls["childBirthDate"].updateValueAndValidity();
    }
  }

  private checkInitialDate() {
    const today = new Date().toLocaleDateString('pt-BR');
    this.startDateError = this.form.controls.startDate.dirty && this.form.controls.startDate.value && this.dateService.calculateDiffDays(this.form.controls.startDate.value, today) > num6 && this.absenceType !== this.type.MATERNITY;
    this.startDateErrorChange.emit(this.startDateError);
    this.initDate.isError = this.startDateError;
  }
//bornDateIsError
  private checkBirthDate() {
    let empty;
    if (!this.enabledCheckBirth) {
      this.checkBirthDateErrorChange.emit(false);
      this.bornDate ?  this.bornDate.isError = false : empty = '';
    }
    else {
      this.checkBirthDateError = (this.form.controls['childBirthDate'].value &&
        this.form.controls['childBirthDate'].value !== '' &&
        this.form.controls.startDate.value &&
        this.form.controls.startDate.value !== '') &&
        (this.form.controls.startDate.value !== this.form.controls['childBirthDate'].value)
      this.checkBirthDateErrorChange.emit(this.checkBirthDateError);
      this.bornDate ? this.bornDate.isError = this.checkBirthDateError : empty = '';
    }
  }

  private reasonValidator() {
    if (this.reasonEnabled) {
      this.form.controls['reason'].setValidators(Validators.required);
      this.form.controls['reason'].setValue(null);
      this.form.controls["reason"].updateValueAndValidity();
    }
    else {
      this.form.controls['reason'].clearValidators();
      this.form.controls['reason'].setValue(null);
      this.form.controls["reason"].updateValueAndValidity();
    }
  }

  public isAdoption() {
    if(this.form.controls.reason.value && this.form.controls.reason.value.reasonCode === REASON_CODE_ADOPTION){
      return true
    }
    return this.form.controls.reason.value
      && this.form.controls.reason.value.childAdoptionEnabled
      && this.form.controls.reason.value.childAdoptionEnabled.toUpperCase() === "Y"
  }

  public isBorn() {
    if(this.form.controls.reason.value && this.form.controls.reason.value.reasonCode === REASON_CODE_ADOPTION){
      return false
    }
    return this.form.controls.reason.value
      && this.form.controls.reason.value.childBirthEnabled
      && this.form.controls.reason.value.childBirthEnabled.toUpperCase() === "Y"
  }

  public licencaPaternidade28DiasAdocao() {
    return this.form.controls.reason.value && this.form.controls.reason.value.reasonCode === REASON_CODE_ADOPTION
  }

  private finalDate() {
    const requestType = this.form.controls.type.value.id;
    const typeOfContract = this.form.controls.type.value.contractFixed;
    const noApplyRules = [num16, num17, num18, num19];

    if(this.disableEndDate && this.form.controls.type.value.numPeriod && typeOfContract === CONTRACT_INDETERMINED){

      return this.finalDateSet();

    } else if(typeOfContract === CONTRACT_DETERMINED && noApplyRules.includes(requestType)){

      return this.finalDateSet();

    }
    else {

      this.endDate.disabled = false;
      return this.form.controls.endDate.value;
    }
  }

  private finalDateSet() {
    const date = new Date(this.dateService.convertPtBRtoGlobal(this.form.controls.startDate.value));
    date.setDate(date.getDate() + Number(this.form.controls.type.value.numPeriod) - 1);
    const invalidDate = isNaN(date.getTime());
    this.form.controls.endDate.setValue(invalidDate ? '' : date.toLocaleDateString('pt-BR'));

    this.endDate.disabled = this.disableEndDate;
    return this.form.controls.endDate.value;
  }

  private checkFinalDate() {
    const endDate = this.form.controls.endDate.value;
    if (this.finalDateError && endDate === ''){
      return;
    }

    const startDate = this.form.controls.startDate.value;
    const diffDate = this.dateService.calculateDiffDays(startDate, endDate) < 0;
    const maxFinalDate = this.maxFinalDate();
    const minFinalDate = this.minFinalDate();
    const oneDay = 1;
    const inMaxFinalDate = this.limitedDate && maxFinalDate && endDate && this.form.controls.type.value.periodLimitMax ? this.dateService.calculateDiffDays(this.form.controls.startDate.value, this.form.controls.endDate.value) + oneDay <= this.form.controls.type.value.periodLimitMax : true;
    const inMinFinalDate = this.limitedDate && minFinalDate && endDate && this.form.controls.type.value.periodLimitMin ? this.dateService.calculateDiffDays(this.form.controls.startDate.value, this.form.controls.endDate.value) + oneDay >= this.form.controls.type.value.periodLimitMin : true;
    const error = !!startDate && !!endDate && (diffDate || ((!inMaxFinalDate && !!maxFinalDate) || (!inMinFinalDate && !!minFinalDate)));

    this.finalDateErrorChange.emit(error);
    this.finalDateError = error;
    // this.endDate.isError = error;
    this.endDate.disabled = true;

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
