
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { HubConnectorModule }from "@afe/base-geral";

import { EnAccessType } from '../model/sample.model';
import { SettingsService } from '../services';

export class SantanderAuthModule {
    static forRoot(settingsService: SettingsService ): ModuleWithProviders {
        // if (settingsService.getAccessType() === EnAccessType.External) {
            return HubConnectorModule.forExternal(() => {
              return null
            });
            ;
        // } else {
        //     return HubConnectorModule.forRoot('url_hub_sso');
        // }
    }
}
