import { EncriptionService, HubConnectorComponent, RequestOptions } from '@afe/base-geral';
import { HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { SettingsService, StorageService } from '.';
import { Functions } from '../lib/functions';
import { Authenticate, EnAccessType, Status } from '../model';
import { LocalStorageService } from '../modules/storage/local-storage.service';


@Injectable()
export class AuthService {
    public auth: Authenticate;
    private readonly storageService: StorageService;
    private readonly hubconnector: HubConnectorComponent;

    constructor(
        public settingsService: SettingsService,
        hubconnector: HubConnectorComponent,
        storageService: StorageService,
        public encriptionService: EncriptionService,
        @Optional() public local: LocalStorageService
    ) {
        this.storageService = storageService;
        this.hubconnector = hubconnector;
    }

    getUserImpersonate() {
        if (!this.auth) {
            this.getUserImpersonateLocal();
        }
        return (this.settingsService.IMPERSONATE_ACTIVE ? this.local.storage.getItem('u') : null);
    }

    getUserImpersonateLocal() {
        return (this.settingsService.IMPERSONATE_ACTIVE && this.local) ? this.local.storage.getItem('u') : null;
    }

    setTicket() {
        // if (typeof findGetParameter !== 'undefined') {
        //     const ticket = findGetParameter('ticket');
        //     if (ticket != null && ticket.length > 0) {
        //         this.local.storage.setItem('t', ticket);
        //     }
        // }
    }

    hubAuthentication() {
        // const u = this.getUserImpersonate();
        // if (this.settingsService.getAccessType() === EnAccessType.Internal) {
        //     this.encriptionService.changeKeys();
        //     return this.hubconnector.authenticate()
        //         .pipe(map((reponse: HttpResponse<any>) => reponse.body))
        //         .pipe(
        //             map((response) => {
        //                 this.auth = new Authenticate();
        //                 this.auth.status = Status.Sucesso;
        //                 this.auth.id = ''

        //                 if (this.settingsService.IMPERSONATE_ACTIVE) {
        //                     if (u) {
        //                       this.auth.id = Functions.pad_zero(u);
        //                     }
        //                 }
        //                 this.storageService.setItem('storage_auth', this.auth);

        //                 return this.auth;
        //             })
        //         )
        //         .pipe(catchError(this.handleError));

        // } else {
            // this.setTicket();

            const ticket = this.local.storage.getItem('t');
            const hasTicket = ticket !== 'null';

            if (hasTicket) {
                return this.validateToken(ticket)
                    .pipe(map(response => {
                        this.auth = new Authenticate();
                        this.auth.status = Status.Sucesso;
                        this.auth.id = Functions.pad_zero(response.matricula?response.matricula:response.body.matricula);
                        this.storageService.setItem('storage_auth', this.auth);
                        return this.auth;
                    }))
                    .pipe(catchError(this.handleError));

            }
            this.auth = this.getAuthenticate();
            return throwError(this.auth.message)
            .pipe(this.authUndefined());

        // }
    }


  private authUndefined() {
    return this.auth = undefined;
  }

    authenticate(): Observable<any> {
        this.setTicket();
        if (this.auth) {
            return of(this.auth.id)
                .pipe(mergeMap((emplId) => {
                    if (this.settingsService.IMPERSONATE_ACTIVE && this.local !== undefined) {
                        if (this.auth.id === emplId) {
                            return of(this.auth);
                        } else {
                            return throwError('Alteração no IMPERSONATE_ACTIVE detectado!, recarregue a página!');
                        }
                    } else {
                        return of(this.auth);
                    }
                }));
        }
        else {

            return this.hubAuthentication();
        }
    }

    validateToken(ticket) {


        const obj = {ticket};

        return this.hubconnector.post(this.settingsService.authenticate, obj, this.getOptions())
            .pipe(map(resp => resp instanceof Array ? resp[0] : resp));
    }

    private getOptions(searchParams?: HttpParams, header?: HttpHeaders): RequestOptions {
        return { params: { 'gw-app-key': this.settingsService.getAppKey() } };
    }

    getAuth(): Authenticate {
        return this.auth;
    }
    getAuthId(): string {
        return !!this.auth?this.auth.id : undefined;
    }

    setAuth(id: string) {
        this.auth.id = id;
    }

    validateAuth(id: string) {
        if (id === this.auth.id) {
            return true;
        } else {
            return false;
        }
    }

    handleError(error?: HttpErrorResponse) {
        const errorMessage = (error && error.error instanceof ErrorEvent) ? error.error.message : error.error;
        this.auth = this.getAuthenticate(false, errorMessage);
        this.storageService.setItem('storage_auth', this.auth);
        return throwError(this.auth.message).pipe(tap(this.authUndefined()));
    }

    getAuthenticate(success = false, message = 'Ocorreu um erro na autenticação.'): Authenticate {
        const auth = new Authenticate();
        auth.status = success ? Status.Sucesso : Status.Error;
        auth.message = message;
        return auth;
    }
    // authenticate(): Observable<any> {
    //     // this.setTicket();
    //     if (this.auth) {
    //         return of(this.auth.id)
    //             .pipe(mergeMap((emplId) => {
    //                 if (this.settingsService.IMPERSONATE_ACTIVE && this.local !== undefined) {
    //                     if (this.auth.id === emplId) {
    //                         return of(this.auth);
    //                     } else {
    //                         return throwError('Alteração no IMPERSONATE_ACTIVE detectado!, recarregue a página!');
    //                     }
    //                 } else {
    //                     return of(this.auth);
    //                 }
    //             }));
    //     }
    //     return this.hubAuthentication();
    // }
}
