import { Component, Input, OnInit } from '@angular/core';
import { AbsenceType } from '../../enum/absence-type.enum';
import { CreatedAbsenceResponse } from '../../interfaces/created-absence-response.interface';
import { FormResume } from './../../interfaces/form-resume.interface';
import { SelectedType } from '../../model/selected-type';

@Component({
  selector: 'wkd-form-resume',
  templateUrl: './form-resume.component.html',
  styleUrls: ['./form-resume.component.scss']
})
export class FormResumeComponent {
  @Input() public questionsLabel = "Questionário";
  @Input() public cidLabel = "CIDs relacionados ao seu atestado";
  @Input() public formData: FormResume;
  @Input() public formsRequired: boolean;
  @Input() public creationData: CreatedAbsenceResponse;
  @Input() public certificateTypeLabel = "Seu atestado";
  @Input() public selectedType: SelectedType;
  openModal = true;
}
